import { Elements } from '@stripe/react-stripe-js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPaymentPlatform } from '../../../actions/payment';
import CheckoutForm from './CheckoutForm';
import useI18n from "@/hooks/useI18n";

/*
 * Feature component
 *
 * Responsible for tacking the `idemId` and rendering the dialog content as `CheckoutForm`.
 *
 * - Responsible for passing the `itemId` and handlers props to the `CheckoutForm` component.
 * - Responsible to ensure that the payingInsatance at the reducer is setted, and setted once.
 * - Responsible to use the `Elements` component of the stripe API
*/
const StripePaymentProvider = ({}) => {
  const dispatch = useDispatch();
  const { stripePromise } = useSelector((state) => state.payment);
  const { currentLang } = useI18n();

  useEffect(() => {
    if (stripePromise)
      return
    if (!stripePromise) {
      dispatch(fetchPaymentPlatform("stripe")); // Is fetched Once
    } 
  }, [stripePromise]);

  
  return <>
    { stripePromise && 
      <Elements stripe={stripePromise} options={{locale: currentLang.langCode}}>
        <CheckoutForm/>
      </Elements>
    }
  </>;

}

export default StripePaymentProvider;

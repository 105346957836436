import { fetchFeatureFlags } from "@/actions/featureFlags";
import { RootState } from "@/store";
import { FeatureFlags } from "@/types/models/services/feature_flags";
import { FC, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export interface Props {
  flagName: keyof FeatureFlags;
  children: ReactNode;
}
const FeatureFlag: FC<Props> = ({ children, flagName }) => {
  const dispatch = useDispatch();
  const { data, status } = useSelector((root: RootState) => root.featureFlags);

  useEffect(() => {
    if (!data && !status.isLoading) {
      dispatch(fetchFeatureFlags());
    }
  }, []);

  if (data && flagName in data && data[flagName])
    return children;
  else if (!data)
    return children;
  return null;
};

export default FeatureFlag;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
  fetchSettingsRequest, 
  receiveSettings, 
  // receiveSettingsError 
} from "../actions/settings";
import { default as settingsService } from "../services/settings";
import { fetchProfile } from "../../../actions/profile";
import { RootState } from "@/store";
import { Settings } from "../types/settings";
import { merge } from "lodash";
import { default as FileSettings } from "../../../settings";
interface IReduxSettings {
  data: Settings | null;
  isFetching: boolean;
  loaded: boolean;
  error: boolean;
}

const useSettingsProvider = () => {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth?.isAuthenticated);
  const settings = useSelector((state: RootState) => state.settings) as IReduxSettings;

  const readLocalSettingsFile = () => {
    return FileSettings as Settings;
  }

  const fetchSettings = () => {
    dispatch(fetchSettingsRequest())
    settingsService.fetchSettings()
    .then(res => {
      const localSettings = readLocalSettingsFile();
      const newSettings = merge(localSettings, res);
      dispatch(receiveSettings(newSettings));
    // TODO
    // }).catch(err => {
    //   if (err.response.status === 409 || err.status === 409) {
    //     dispatch(receiveSettingsError(err.response.data));
    //   }
    }).catch(err => {
      const localSettings = readLocalSettingsFile();
      dispatch(receiveSettings(localSettings))
    })
  }

  useEffect(() => {
    if (settings.data == null) {
      fetchSettings()
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchProfile())
    }

  }, [isAuthenticated]);

  return {
    settings
  }
  
}

export default useSettingsProvider;
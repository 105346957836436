
import { Alert } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NewsletterPageState } from "./useNewsletterPage";

interface Props {
  state: NewsletterPageState;
}

const NewsletterAlerts: FC<Props> = ({ state }) => {
  const { t } = useTranslation();

  if (state.jwtError) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        {t("text.newsletter_fetch_subscriber_data_error")}
      </Alert>
    );
  }

  return (
    <>
      {state.changeMailSuccess && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {t("text.newsletter_change_mail_success")} 🎉
        </Alert>
      )}
      {state.changeMailError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {t("text.newsletter_change_mail_error")}
        </Alert>
      )}
      {state.subscriptorRemovedSuccess && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {t("text.newsletter_desubscription_all_success")}
        </Alert>
      )}
      {state.submitSubcriptions == "Success" && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {t("text.newsletter_subscribtion_success")} 🎉
        </Alert>
      )}
      {state.submitSubcriptions == "Error" && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {t("text.newsletter_subscribtion_error")}
        </Alert>
      )}
    </>
  );
};

export default NewsletterAlerts;

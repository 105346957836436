import { Button, DialogActions, DialogContent, DialogTitle, Typography, Alert } from "@mui/material";
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetPaymentStatus } from '../../../actions/payment';
import Settings from '../../../settings';
import { LoadingAnimation } from '../../LoadingAnimation';
import DownloadTransactionReportButton from '../DownloadTransactionReportButton';
import { PaymentPlatformContext } from '../PaymentPlatformProvider';

/*
 * Feature component
 *
 * Responsible to render the dialog content with the transaction details.
 *
 * - Responsible to handle the resetPaymentStatus reducer action and onClose event.
 * - Responsible to render the `DownloadTransactionReportButton` component
*/
 const SuccessPaymentDialogContent = ({}) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { success } = useSelector((state) => state.payment);
  const { onClose } = useContext(PaymentPlatformContext);

  const handleClose = () => {
    dispatch(resetPaymentStatus());
    onClose();
  }

  if (!success)
    return <LoadingAnimation/>


  const drawedAditionalInformation = <>
    {
      (Settings.organization.email) ? <>
        {/* Additional Information */}
        <Typography variant="subtitle1" paragraph>
          { i18n.t("common:text.transaction_aditional_information")}
        </Typography>
        <Typography variant="body2" paragraph>
          { i18n.t("common:text.transaction_contact_support_message") + Settings.organization.email}
        </Typography>
      </>
      :
      null
    }
  </>
    
  let actionButton = null;
  let drawedTransactionInformation = <>
    <Alert severity='info'>
      <Typography variant="subtitle1" paragraph>
        { i18n.t("common:text.transaction_not_found")}
      </Typography>
      <Typography variant="body2" paragraph>
        { i18n.t("common:text.transaction_not_found_message")}
      </Typography>
      {/* { drawedAditionalInformation } Desactivat, si quan tornis a veure aquest jsx comentat  */}
    </Alert>
  </>;


if (success.error !== "internal_error") {
    drawedTransactionInformation = <>
      <Alert severity='success'>
        { success["paid_by_user_email"] && i18n.t("common:text.transaction_success_message", {email: success["paid_by_user_email"]} ) }
        { !success["paid_by_user_email"] && i18n.t("common:text.transaction_success_message_without_email") }
      </Alert>
      {/* Transaction Details */}
      <Typography variant="subtitle1" paragraph sx={{fontWeight: "bold"}}>
        { i18n.t("common:text.transaction_details")}
      </Typography>
      <Typography variant="body2" paragraph>
        { i18n.t("common:text.transaction_id") } {success['transaction_id']}
      </Typography>
      <Typography variant="body2" paragraph>
        { i18n.t("common:text.transaction_quantity") } {success['amount']}{success['currency']}
      </Typography>
      <Typography variant="body2" paragraph>
        {/* Fecha y Hora: 14 de oviembre de 2023, 15:30 */}
        { i18n.t("common:text.transaction_time") } {success['last_transaction_update_at']}
      </Typography>

      {/* Invoice Details */}
      <Typography variant="subtitle1" paragraph sx={{fontWeight: "bold"}}>
        { i18n.t("common:text.transaction_invoice_details") }
      </Typography>
      <Typography variant="body2" paragraph>
        { i18n.t("common:text.transaction_invoice_number") } {success['invoice_number']}
      </Typography>
      <Typography variant="body2" paragraph>
        { i18n.t("common:text.transaction_concept") } {success['invoice_concept'] }
      </Typography>

      {/* Payment Method */}
      <Typography variant="subtitle1" paragraph sx={{fontWeight: "bold"}}>
        { i18n.t("common:text.transaction_payment_method_details") }
      </Typography>
      <Typography variant="body2" paragraph>
        { i18n.t("common:text.transaction_payment_method_id") } {success["payment_method_id"]}
      </Typography>
      <Typography variant="body2" paragraph>
        { success["payment_type"] === "credit_card" && i18n.t("common:text.transaction_payment_method_credit_card") }
      </Typography>
      {/* { drawedAditionalInformation } Desactivat, si quan tornis a veure aquest jsx comentat  */}
    </>;
    actionButton = <DownloadTransactionReportButton invoiceId={success["invoice_id"]}/>
  }
  return (
    <>
      <DialogTitle>
          { i18n.t("common:text.transaction_payment_success") }
      </DialogTitle>
      <DialogContent>
        { drawedTransactionInformation } 
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          { i18n.t("common:text.payment_form_close") }
        </Button>
        { actionButton }
      </DialogActions>
    </>
  )
}


export default SuccessPaymentDialogContent;
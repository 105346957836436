import { Box, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowForward, Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import {
  NewsletterRead,
  OfuscatedId,
  SubscriberEmails,
  SubscriberId,
} from "@/types/models/services/newsletter";
import { NewsletterPageState, SelectedNewsletters } from "./useNewsletterPage";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

interface Props {
  subscriberId: OfuscatedId | SubscriberId | null;
  emails: SubscriberEmails;
  newsletters: NewsletterRead[];
  state: NewsletterPageState;
  onSubscribe: () => void;
  selectedNewsletters: SelectedNewsletters;
}

const NewsletterActions: FC<Props> = ({
  emails,
  newsletters,
  selectedNewsletters,
  state,
  onSubscribe,
  subscriberId
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  let buttonAction = <></>;
  if (state.fetchNewsletters == "Success") {
    if (selectedNewsletters.length && !subscriberId) {
      buttonAction = (
        <Button
          variant={"contained"}
          color="success"
          fullWidth
          onClick={() => onSubscribe()}
        >
          {t("text.newsletter_subscribe_first_confirmation")}
        </Button>
      );
    } else if (selectedNewsletters.length) {
      buttonAction = (
        <Button
          variant={"contained"}
          color="primary"
          fullWidth
          onClick={() => onSubscribe()}
        >
          {t("text.newsletter_subscribe")}
        </Button>
      );
    } else {
      buttonAction = (
        <Button
          variant={"contained"}
          color="error"
          fullWidth
          onClick={() => onSubscribe()}
        >
          {t("text.newsletter_unsubscribe")}
        </Button>
      );
    }
  }

  let buttonNavigate = <></>;
  if (!isAuthenticated) {
    buttonNavigate = (
      <Button
        variant={"outlined"}
        color="primary"
        fullWidth
        onClick={() => navigate(t("common:url.login"))}
        startIcon={<Person />}
      >
        {t("common:text.login_title")}
      </Button>
    );
  } else {
    buttonNavigate = (
      <Button
        variant={"outlined"}
        color="primary"
        fullWidth
        onClick={() => navigate("/")}
        startIcon={<ArrowForward />}
      >
        {t("common:text.go_home")}
      </Button>
    );
  }

  return (
    <Stack direction="row" sx={{ gap: 2 }}>
      <Box sx={{width: "100%"}}>
        {buttonAction}
      </Box>
      <Box sx={{width: "25%"}} alignSelf="flex-end">
        {buttonNavigate}      
      </Box>
    </Stack>
  );
};

export default NewsletterActions;

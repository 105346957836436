import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterContractsInvoicesRequest, viewContract, fetchContracts } from '../../actions/contracts';
import { isContractationEnabled, locale_code } from '../../constants/i18n';
import { regional_settings } from '../../constants';
import Settings from "../../settings";

import ContractsPagination from './ContractsPagination'
import { LoadingAnimation } from '../LoadingAnimation';
import { SmartTable, Button } from '@gisce/oficina-virtual-components'

import { Box, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Receipt, ZoomIn, ErrorOutline, Search, Delete, Add } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { contraction_url } from "@/constants/i18n";

const ContractsListElectricity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 
    items: contracts = [], 
    loading
  } = useSelector((state) => state.contracts);
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useState({
    page: 1, 
    limit: Settings?.contracts?.itemsPerPage ?? 10, 
    text: ''
  });
  
  const fetchData = (initial=true) => {
    dispatch(fetchContracts(initial, (searchParams.page - 1) * searchParams.limit, searchParams.limit, searchParams.text));
  };

  useEffect(() => {
    if (searchParams.text) {
      // Debouncer when search parameters are entered
      const debounceForm = setTimeout(() => {
        fetchData(false);
      }, 1500);

      return () => clearTimeout(debounceForm);
    } else {
      // Instant fetch if search parameters are removed
      fetchData(false);
    }

  }, [...Object.values(searchParams)]);

  const goToInvoice = (contract) => {
    dispatch(filterContractsInvoicesRequest(contract, contract.name));
    navigate(t('common:url.contracts_invoices', {contractName: contract?.name}));
  };

  const goToContract = (id) => {
    const contract = contracts.filter((c) => c.name === id)[0];
    dispatch(viewContract(contract));
    navigate(t('common:url.contracts_detail', {contractName: contract.name}));
  };

  const handleItemsPaginationChange = (page, limit) => {
    setSearchParams({page, limit, text: searchParams.text});
  };

  const JSON_to_arrays = () => {
    let columns = [
      {
        title: t('common:text.contracts_contract'),
        key: "name",
      },
      {
        title: t('common:text.contracts_cups'),
        key: "cups",
      },
      {
        title: t('common:text.contracts_direction'),
        key: "address",
      },
      {
        title: t('common:text.contracts_product'),
        key: "tariff",
        removeSelf: !Settings?.contracts?.showProduct
      },
      {
        title: t('common:text.contracts_power'),
        key: "power",
        removeSelf: !Settings?.contracts?.showPower
      },
      {
        title: t('common:text.contracts_state'),
        key: "state",
      },
      {
        title: t('common:text.contracts_payment'),
        key: "payment",
      },
      {
        title: t('common:text.contracts_actions'),
        key: "actions",
      },
    ];

    // Remove any item with removeSelf set to true
    columns = columns.filter(item => !item.removeSelf);

    const contractsSource = contracts ? contracts.map( (contract, index) => {

      const invoices_button = (
        <Button
          key={"invoices"}
          variant={'contained'}
          color={'primary'}
          disabled={!contract.name}
          title={t("common:text.contract_related_invoices")}
          onClick={() => goToInvoice(contract)}
          style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden' }}
        >
          {<Receipt sx={{mr: 1}} />}
          {t('common:text.contracts_view_invoices_button_button')}
        </Button>
      );

      const contract_button = (
        <Button
          key={"contract"}
          variant={'contained'}
          color={'primary'}
          disabled={!contract.name}
          title={t("common:text.contract_view_detail")}
          onClick={() => goToContract(contract.name)}
          style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden' }}
        >
          {<ZoomIn sx={{mr: 1}} />}
          {t('common:text.contracts_view_contracts_button_button')}
        </Button>
      );

      let contractStatus;
      switch (contract.state) {
        case 'esborrany':
          contractStatus = t('common:text.contract_status_draft');
          if (!contract.sign_date) {
            contractStatus = t('common:text.contract_status_signature_pending');
          }
          break;
        case 'activa':
          contractStatus = t('common:text.contract_status_open');
          break;
        case 'baixa':
          contractStatus = t('common:text.contract_status_closed');
          break;
        default:
          contractStatus = t('common:text.contract_status_closed');
          break;
      }

      const hasDebt = contract.amount_debt > 0;
      const contractPayment = hasDebt ? `${t('common:text.contract_debt')}  (${contract.amount_debt.toLocaleString(locale_code)} €)` : '';
      const contractedPowers = (contract.contracted_power_period && contract.contracted_power_period.length) ? contract.contracted_power_period.reduce((accumulator, currentValue) => {
        return `${accumulator} ${currentValue.period}: ${currentValue.power.toLocaleString(locale_code)} ${regional_settings.potence_unit}`;
      }, '') : '';

      const values = {
        id: index,
        name: contract.name,
        cups: contract.cups.name,
        address: `${contract.cups.street} - ${contract.cups.city}`,
        tariff: (contract.pricelist && contract.pricelist.nom_comercial) ? contract.pricelist.nom_comercial : "",
        power: contractedPowers,
        state: contractStatus,
        payment: contractPayment,
        actions: [invoices_button, contract_button]
      };

      return values;
    }) : [];

    return {
      columns,
      contractsSource
    };
  }

  const getStatusForItem = (item) => {
    if (item?.hasDebt) {
      return <ErrorOutline style={{color: '#FF0000'}}/>
    }
  }

  const renderContractList = () => {

    const adaptedData = (loading === 'succeeded') && JSON_to_arrays();

    return (
      <>
        <Stack spacing={3}>

          <Stack 
            direction={{md: "row"}} 
            gap={2}
            justifyContent="space-between"
            alignItems={{xs: "initial", md: "center"}}
          >
            <TextField
              label={t("common:text.contracts_search_filter_info")}
              id="contract-list-search-filter"
              disabled={loading === "pending"}
              variant='filled'
              sx={{width: {lg: "35vw", xs: "100%"}}}
              onChange={(event) => setSearchParams({
                page: 1, limit: searchParams.limit, text: event.target.value
              })}
              value={searchParams.text}
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <Search />
                </InputAdornment>,
                endAdornment: searchParams.text && <InputAdornment position="end">
                    <IconButton
                      aria-label="remove search filter text"
                      onClick={(_event) => setSearchParams({
                        page: 1, limit: searchParams.limit, text: ''
                      })}
                      edge="end"
                    >
                      <Delete />
                    </IconButton>
                  </InputAdornment>
              }}
            />

            {isContractationEnabled && <Box>
              <Button 
                variant='contained'
                startIcon={<Add/>}
                onClick={() => navigate(t(contraction_url))}
              >
                {t("common:text.new_customer_title")}
              </Button>
            </Box>}
            
          </Stack>

          {loading === 'succeeded' ?
            <SmartTable
              columns={ adaptedData?.columns ?? []} 
              dataSource={ adaptedData?.contractsSource ??[] }
              loading={loading === 'pending'}
              onRowStatus={getStatusForItem}
              loadingComponent={<LoadingAnimation />}
            />
          :
            <LoadingAnimation/>
          }

          <ContractsPagination 
            page={searchParams.page}
            onItemsPaginationChange={(page, limit) => handleItemsPaginationChange(page, limit)} 
            type='electricity'
          />

        </Stack>
      </>
    );
  };

  return renderContractList();
}

export default ContractsListElectricity;

import { FC, useEffect, useState } from "react";
import { TextField, Button, Alert, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { z } from "zod";
import { useTranslation } from "react-i18next";
import Settings from "@/settings";
import { OfuscatedId, SubscriberId } from "@/types/models/services/newsletter";

interface Props {
  value: string;
  subscriberId?: OfuscatedId | SubscriberId | null;
  onMailChangeConfirmed: (mail: string) => void;
  onEditNewEmailClick: (newMail: string) => void;
  hideSubmitButton?: boolean;
}
const NewsletterEmailInput: FC<Props> = ({
  value,
  onMailChangeConfirmed,
  onEditNewEmailClick,
  hideSubmitButton,
  subscriberId,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(value);
  const [error, setError] = useState(false);
  const [editionActivated, setEditionActivated] = useState(false);
  const [editionEnabled, setEditionEnabled] = useState(false);

  useEffect(() => {
    const enabled = !Settings.newsletter?.mailEditionDisabled && (
      !!subscriberId
    ) && !!email;
    setEditionEnabled(enabled)
  }, [subscriberId, email]);
  useEffect(() => setEmail(value), [value]);

  const emailSchema = z.string().email("Invalid email address");

  const handleModifyEmail = (email: string) => {
    try {
      emailSchema.parse(email); // Validate email
      onEditNewEmailClick(email);
      setEditionActivated(false);
    } catch (e) {
      setError(true);
    }
  }

  const handleSubmitEmail = (email: string) => {
    try {
      emailSchema.parse(email); // Validate email
      onMailChangeConfirmed(email);
      setError(false);
    } catch (e) {
      setError(true);
    }
  };

  const handleToggleActivateEditor = () => {
    setEditionActivated((prev) => !prev);
  };

  return (
    <>
      <Stack direction={"row"}>
        <TextField
          label={t("text.price_comparator_email")}
          variant="outlined"
          fullWidth
          type="email"
          value={email}
          disabled={(editionEnabled && !!email && !editionActivated) || (!editionEnabled && !!subscriberId && !!email)}
          onChange={(e) => { setEmail(e.target.value); !editionEnabled && hideSubmitButton && handleSubmitEmail(e.target.value)}}
          error={error}
          helperText={error && t("text.newsletter_email_not_valid")}
        />
        {editionEnabled && <>
          {!editionActivated && (
            <Button
              variant={"outlined"}
              color={"primary"}
              fullWidth
              sx={{flex: 1}}
              onClick={handleToggleActivateEditor}
            >
              <EditIcon/>
            </Button>
          )}
          {editionActivated && <>
            <Button
              variant={"contained"}
              color={"success"}
              fullWidth
              sx={{flex: 1}}
              onClick={() => handleModifyEmail(email)}
            >
              <CheckIcon/>
            </Button>
            <Button
              variant={"outlined"}
              color={"error"}
              fullWidth
              sx={{flex: 1}}
              onClick={() => setEditionActivated(false)}
            >
              <CancelIcon/>
            </Button>
          </>}
        </>}
      </Stack>
      
      {!hideSubmitButton && (
        <Button
          variant={"contained"}
          color="primary"
          fullWidth
          disabled={!email}
          onClick={() => handleSubmitEmail(email)}
          sx={{ flex: 3 }}
        >
          {t("text.newsletter_continue")}
        </Button>
      )}
    </>
  );
};

export default NewsletterEmailInput;

import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import { Box, Container, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';


/*
* Responsible for render the `CardElement` component and his style
*
* - https://docs.stripe.com/payments/payment-card-element-comparison#advanced-scenarios
*/
const StripeElements =() => {
  const { t } = useTranslation();

  return <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StripeElementDecorator 
          label={t('common:text.credit_card_number')}
          name="ccnumber"
          stripeElement={CardNumberElement}
        />
      </Grid>
      <Grid item xs={6}>
        <StripeElementDecorator 
          label={t('common:text.expiration_date')}
          name='ccexp'
          stripeElement={CardExpiryElement}
        />
      </Grid>
      <Grid item xs={6}>
        <StripeElementDecorator 
          label={t('common:text.cvc')}
          name="cvc"
          stripeElement={CardCvcElement}
        />
      </Grid>
    </Grid>
  </>
};

/*
* Responsible for:
*   - Rendering the input fields
*   - Customizing the user interface with material design elements
*   - Integrating the Stripe React component
*   - Handling error management
*/
const StripeElementDecorator = ({
  stripeElement: StripeElement, ...props
}) => {
  const [ stripeError, setStripeError] = useState(null);

  return (
      <TextField
        {...props}
        variant="outlined"
        required
        fullWidth
        InputLabelProps={{shrink: true}}
        error={!!stripeError}
        helperText={stripeError?.message}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: StripeElement,
            onChange:((e) => setStripeError(e.error))
          }
        }}
      />
  );
}

/*
* Responsible to act as a bridge between the Component provided(Stripe component), and the root component
* Exposes the focus, and set focus instead of the root component to the provided stripe component
* - Component based from: https://plainenglish.io/blog/stripe-payment-form-with-reactjs-and-material-ui-part-4-118e60fca962
*/
const StripeInput = forwardRef((
  { component: Component, ...props }, ref
) => {
  useImperativeHandle(ref, () => ({
    focus: () => ref.current.focus,
  }));


  return (
    <Component
      onReady={element => (ref.current = element)}
      {...props}
    />
  );
})

export { StripeInput }
export default StripeElements;

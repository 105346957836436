import { ILanguage } from "@gisce/oficina-virtual-components";
import { getAvailablePartnerLangs } from "../i18n";
import { dayjs } from "../config";
import { useTranslation } from "react-i18next";
import Settings from "../settings";
import cookies from "@/utils/cookies";

const useI18n = () => {
  const { i18n, t } = useTranslation();
  const undefinedLang: ILanguage = {display: '', displayShort: '', value: '', langCode: ''};

  const availableLangs: ILanguage[] = getAvailablePartnerLangs().map((lang) => {
    const language: ILanguage = {
      display: lang.displayName,
      displayShort: lang.displayShort,
      value: lang.localeCode,
      langCode: lang.langCode
    }
    return language;
  });

  const changeLang = (newLang: ILanguage | string ) => {
    if (typeof newLang === "string" || !newLang) {
      const switchToLang = availableLangs.filter(lang => lang.value === newLang);
      if (switchToLang.length > 0) {
        newLang = switchToLang[0];
      }  else {
        newLang = availableLangs[0];
      }
    }

    // Change the language and update the pathname
    i18n.changeLanguage(newLang.value);
    dayjs.locale(newLang.value);
    cookies.saveLang(newLang.value);
  };

  const currentLang: ILanguage = availableLangs.find(lang => lang.value === i18n.language) ?? undefinedLang;

  const getContractationURL = () => {
    switch (Settings.features.newContractType) {
      case 'electricitat':
        return t('common:url.electricityContractation');
      case 'gas':
        return t('common:url.gasContractation');
      case 'dual':
        return t('common:url.dualContractation');
      default:
        return t('common:url.electricityContractation');
    }
  }

  const getAllLangsPath = (path: string): string[] => {
    return availableLangs.map(({value}) => i18n.getFixedT(value)(path));
  }

  return {
    availableLangs,
    changeLang,
    currentLang,
    getContractationURL,
    getAllLangsPath
  }
}

export default useI18n;
import { FC, ReactNode, createContext } from "react";
import { Settings } from "../types/settings";
import useSettingsProvider from "../hooks/useSettingsProvider";
import { LoadingAnimation } from "@/components/LoadingAnimation";

interface ISettingsProviderProps {
  children: ReactNode;
}

const SettingsProvider: FC<ISettingsProviderProps> = ({ children }) => {

  const { settings } = useSettingsProvider();

	return <SettingsProviderContext.Provider value={settings.data}>
    {settings.isFetching && <LoadingAnimation/>}
		{settings.loaded && !settings.error && children}
		{settings.loaded && settings.error && <div>Error fetching settings</div>}
	</SettingsProviderContext.Provider>
}

export default SettingsProvider;

export const SettingsProviderContext = createContext<null | Settings>(null);

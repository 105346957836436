import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingAnimation } from '../../LoadingAnimation';

/**
 * UI Component
 * 
 * Responsible for rendering the dialog content with a loading animation 
 */
const FetchingPaymentDialogContent = ({}) => {
  const { i18n } = useTranslation();
  return (
    <>
      <DialogTitle>
        { i18n.t("common:text.payment_in_process") }
      </DialogTitle>
      <DialogContent style={{overflow: 'hidden'}}>
        <LoadingAnimation/>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </>
  )
}


export default FetchingPaymentDialogContent;
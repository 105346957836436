import Settings from "@/settings";
import { Chip, Stack, styled, useTheme } from "@mui/material";
import ChipedPaymentStatusText from "./ChipedPaymentStatusText/ChipedPaymentStatusText";
import ChipedPaymentStatusIcon from "./ChipedPaymentStatusIcon/ChipedPaymentStatusIcon";
import ChipedPaymentStatusInfoButton from "./ChipedPaymentStatusInfoButton/ChipedPaymentStatusInfoButton";
import ChipedPaymentStatusStyle from "./ChipedPaymentStatusStyle/ChipedPaymentStatusStyle";
import { Invoice } from "../PaymentStatus";

interface IChipedPaymentStatusProps {
  invoice: Invoice;
}

const MuiChipedPaymentStatus = styled(Chip, {
  name: 'MuiChipedPaymentStatus'
})({});

const ChipedPaymentStatus = ({
  invoice
}: IChipedPaymentStatusProps) => {
  
  const theme = useTheme();
  let paid = null;
  let paidText = ChipedPaymentStatusText(invoice);
  let paidIcon = (Settings?.invoices?.chipedPaymentStatus?.icon as boolean) ? ChipedPaymentStatusIcon(invoice) : undefined;
  let paidColor = ChipedPaymentStatusStyle(invoice);
  const defaultProps = theme.components?.MuiChipedPaymentStatus?.defaultProps;

  if (paidText) {
    let infoButton = ChipedPaymentStatusInfoButton(invoice, paidColor);

    paid = <>
      <Stack direction={'row'} gap={0.5} alignItems={'center'}>
        <MuiChipedPaymentStatus
          // TODO Resoldre el problema de passar els defaultProps a traves de la funcio styled
          // tal i com s'indica que es pot fer a https://mui.com/system/styled/ usant el 'name'
          {...defaultProps}
          icon={paidIcon}
          label={paidText}
          color={paidColor}
        />
        {infoButton}
      </Stack>
    </>
  }

  return paid
};

export default ChipedPaymentStatus
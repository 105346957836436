import { useEffect, useState } from "react";
import * as invoicesActionCreators from "../../actions/invoices";
import _ from "lodash";
import { Box, Typography, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InvoicesChart from "./InvoicesChart";
import InvoicesTable from "./Table/InvoicesTable";
import InvoicesFilter from "./InvoicesFilter";
import InvoicesPagination from "./InvoicesPagination";
import PayDialog from "../PaymentPlatform/PayDialog";
import { ContentHeader } from "../ContentHeader";
import { useParams } from "react-router-dom";
import Settings from "../../settings";

const paymentProvider = Settings?.paymentProvider ?? "stripe";

const InvoicesElectricityContent = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();

  const invoices = useSelector(state => state.invoices['electricity'].items);
  const itemsOffset = useSelector(state => state.invoices['electricity'].itemsOffset);
  const itemsPage = useSelector(state => state.invoices['electricity'].itemsPage);
  const loaded = useSelector(state => state.invoices['electricity'].loaded);
  const errors = useSelector(state => state.invoices['electricity'].errors);
  const currentFilter = useSelector((state) => state.invoices['electricity'].filters.currentFilter);
  const token = useSelector(state => state.auth.token);


  const [initialFilter, setInitialFilter] = useState([]);
  const [initialItemsPage, setInitialItemsPage] = useState();

  useEffect(() => { //Runs when component is mounted/unmounted
    dispatch(invoicesActionCreators.fetchFilters(token));
  }, []);

  useEffect(() => {  //Runs when the url parameter 'contractName' changes
    if (params?.contractName) {
      setInitialFilter([
        {
          category: { type: "autocomplete", renderText: "Contracte", searchField: "polissa_id.name" },
          value: [params?.contractName], 
          type: 'contains'
        }
      ]);
      dispatch(invoicesActionCreators.fetchInvoices(token, false, `('polissa_id.name', 'in', ['${params.contractName}'])`));
    } else {
      setInitialFilter([]);
      dispatch(invoicesActionCreators.fetchInvoices(token, false, ''));
    }
    setInitialItemsPage(null);
  }, [params?.contractName]);

  const fetchData = () => {
    dispatch(invoicesActionCreators.fetchInvoices(token, false, currentFilter, itemsOffset, itemsPage));
  };

  const applySearchFilter = (newFilters, newItemsPage) => {
    const newItemsOffset = newItemsPage !== itemsPage ? 0 : itemsOffset;
    setInitialItemsPage(newItemsPage);
    dispatch(invoicesActionCreators.fetchInvoices(token, false, newFilters, newItemsOffset, newItemsPage));
  };

  const handleItemsOffset = (newOffset) => {
    dispatch(invoicesActionCreators.fetchInvoices(token, false, currentFilter, newOffset, itemsPage));
  };

  return (
    <>
      <PayDialog
        platformName={paymentProvider}
        onPaymentSuccessful={fetchData} 
        onClose={() => {}}
      />
      <ContentHeader
        title={t('common:text.invoices_view_title')}
        addButton={false}
        refreshButton={true}
        refreshClickMethod={fetchData}
      />

      {!errors ? 
        <div>
          <Box mb={2}>
            <Typography variant={"h6"}>
              {t('common:text.invoices_contracts_chart')}
            </Typography>
          </Box>

          <div style={{ marginBottom: 20 }}>
            <InvoicesChart invoices={invoices} type='electricity'/>
          </div>

          {loaded && invoices?.length === 0 &&
            <Alert sx={{mb: 2}} severity="info">
              {t('common:text.invoices_view_empty_list')}
            </Alert>
          }

          <div style={{ marginBottom: 20 }}>
            <InvoicesFilter 
              initialFilters={initialFilter} 
              initialItemsPage={initialItemsPage}
              applySearchFilter={applySearchFilter}
              type='electricity'
            />
          </div>

          <InvoicesPagination onItemsOffsetChange={handleItemsOffset} type='electricity'/>

          <InvoicesTable type='electricity'/>

          <InvoicesPagination onItemsOffsetChange={handleItemsOffset} type='electricity' />

        </div>
      :
        <Alert severity="warning">{t("common:text.invoices_error_fetching")}</Alert>
      }
        
    </>
  );
}

export default InvoicesElectricityContent;

import { useTranslation } from "react-i18next";
import { Invoice } from "../../PaymentStatus";

const ChipedPaymentStatusText = (invoice: Invoice) => {
  const { i18n } = useTranslation();
  let paidText = "";

  switch (invoice?.paid) {
    case "pending_remesa":
      paidText = i18n.t('common:text.invoice_pending_remesa');
      if (invoice?.rectificative_type === "A" || invoice?.rectificative_type === "B") {
        paidText = i18n.t('common:text.invoice_pending_refunded');
      }
      break;
    case "pending_transfer":
      paidText = i18n.t('common:text.invoice_pending_transfer');
      if (invoice?.rectificative_type === "A" || invoice?.rectificative_type === "B") {
        paidText = i18n.t('common:text.invoice_pending_refunded');
      }
      break;
    case "paid":
    case "paid_without_transaction":
    case "manual_verification_required":
      paidText = i18n.t('common:text.invoice_paid');
      if (invoice?.rectificative_type === "A") {
        paidText = i18n.t('common:text.invoice_refunded');
      }
      break;
    case "unpaid":
      if (invoice?.amount_pending && invoice?.amount_pending != invoice?.amount_total) {
        paidText = i18n.t('common:text.invoices_parcial_payment');
      }
      else {
        paidText = i18n.t('common:text.invoice_not_paid');
      }
      break;
    default:
      paidText = "";
      break;
  }

  return paidText;
}

export default ChipedPaymentStatusText
import useInvoicesTable from "./useInvoicesTable"
import Table from "./Table"


const InvoicesTable = ({type}) => {
  const { invoices, columns, loaded } = useInvoicesTable({type});

  return (
    <Table
      columns={columns}
      loaded={loaded}
      data={invoices}
    />
  )
}

export default InvoicesTable;
/* eslint camelcase: 0 */

import { API_PREFIX, API_SPECIFICATION } from "../constants/index";
import axios from "axios";

import Settings from "../settings";
import { getUrlParams, addParamToResource } from "./misc";
import * as Sentry from "@sentry/react";
import { decodeJwt } from 'jose'
import _ from "lodash";
import Cookies from "js-cookie";
import CookiesUtils from "./cookies";

const tokenConfig = (token) => ({
  headers: {
    Authorization: token, // eslint-disable-line quote-props
  },
});

export const getAPIPrefix = (version = API_SPECIFICATION) => {
  return `/api/v${version}`;
};

const addLangIfPresent = (resource) => {
  const urlParams = getUrlParams();
  if (urlParams && urlParams.lang) {
    return addParamToResource("lang", urlParams.lang, resource);
  }

  const langCookies = CookiesUtils.getLang();
  if (langCookies) {
    return addParamToResource("lang", langCookies, resource);
  }

  return resource;
};

/**
 * Stores token to browser storage and sets to AJAX authorization header.
 */
export function define_token(token) {
  Cookies.set('token', token, { secure: true, sameSite: 'Lax' } );
  axios.defaults.headers.common["Authorization"] = token;
  const token_values = decodeJwt(token);
  Sentry.setUser(token_values);
}

/**
 * Removes token from browser storage and from AJAX authorization header.
 */
export function undefine_token() {
  Cookies.remove('token');
  axios.defaults.headers.common["Authorization"] = "";
}

export function validate_token(token) {
  return axios.post(API_PREFIX + "/is_token_valid", {
    token,
  });
}

export function get_github_access() {
  window.open("/github-login", "_blank");
}

export function create_user(user, password) {
  return axios.post(API_PREFIX + "/user/", {
    user,
    password,
  });
}

export function get_token(user, password) {
  return axios.post(API_PREFIX + "/get_token", {
    user,
    password,
  });
}

export function refresh_token() {
  return axios.get(API_PREFIX + "/user/refresh");
}

export function get_token_by_client_id(clientId, secret) {
  return axios.post(API_PREFIX + "/user/client_id", {
    client_id: clientId,
    secret,
  });
}

export function openid_logout(token) {
  const { providerUrl } = Settings.oidc;
  const { logout } = Settings.oidc.endpoints;
  window.location.href = `${providerUrl}${logout.resource}?${logout.params.token.name}=${token}&${logout.params.redirectUrl.name}=${logout.params.redirectUrl.value}`;
}

export function openid_login(code, nonce) {
  return axios.post(API_PREFIX + "/user/openid", {
    code,
    nonce,
  });
}

export function ask_recover(email) {
  return axios.post(API_PREFIX + "/recover", {
    email,
  });
}

export function has_github_token(token) {
  return axios.get(API_PREFIX + "/has_github_token", tokenConfig(token));
}

export function data_about_user(token) {
  return axios.get(API_PREFIX + "/user/", tokenConfig(token));
}

export async function check_url_exists(
    resource,
    version = API_SPECIFICATION
) {
  try {
    const result = await axios.options(`${getAPIPrefix(version)}/${resource}`);
    if (result.status === 200) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

export function data_fetch_api_resource(
  token,
  resource,
  version = API_SPECIFICATION
) {
  return axios
    .get(getAPIPrefix(version) + "/" + addLangIfPresent(resource))
    .then((response) => {
      // if (response.status === 401) { shouldNavigate(i18n.t('common:url.logout')); }
      return response;
    })
    .catch((error) => {
      // if (error?.response?.status === 401) { shouldNavigate(i18n.t('common:url.logout')); }
      throw error;
    });
}

export function data_fetch_api_resource_with_params(
  token,
  resource,
  params = {},
  version = API_SPECIFICATION
) {
  return axios
    .get(getAPIPrefix(version) + "/" + addLangIfPresent(resource), {
      params,
    })
    .then((response) => {
      if (response.status === 401) { dispatchNewRoute(i18n.t('common:url.logout')); }
      return response;
    })
    .catch((error) => {
      if (error?.response?.status === 401) { dispatchNewRoute(i18n.t('common:url.logout')); }
      throw error;
    });
}

export function data_download_api_resource(
  token,
  resource,
  version = API_SPECIFICATION
) {
  return axios.get(getAPIPrefix(version) + "/" + addLangIfPresent(resource), {
    responseType: "blob",
  });
}

export const data_create_api_resource = async (
  token,
  resource,
  new_data,
  version = API_SPECIFICATION
) => {
  return axios.post(
    getAPIPrefix(version) + "/" + addLangIfPresent(resource),
    new_data
  );
};

export function data_update_api_resource(
  token,
  resource,
  new_data,
  version = API_SPECIFICATION
) {
  return axios.put(
    getAPIPrefix(version) + "/" + addLangIfPresent(resource),
    new_data
  );
}

export function data_delete_api_resource(
  token,
  resource,
  version = API_SPECIFICATION
) {
  return axios.delete(getAPIPrefix(version) + "/" + addLangIfPresent(resource));
}

export const sendElectricityLeads = async (data) => {
  return data_create_api_resource(null, "leads", data).catch((e) => {
    Sentry.captureException(e);
  });
};

export const parseResponse = (response) => {
  // The response from AXIOS is different if it's a Promise.reject or
  // Promise.resolve like
  const statusCode = _.get(response, 'status', _.get(response, 'response.status', 500));
  const data = _.get(response, 'data', _.get(response, 'response.data', {}));
  return [statusCode, data];
}

/*
* Delayed Promise creation
* */
export const pause = (duration) => new Promise(res => setTimeout(res, duration));

/*
* Method to handle retries over a called function until it resolves the Promise.
* In our cas we'll retry the call if the HTTP response is 202 (Accepted)
* */
export const insist = (fn, args=[], retries, delay = 500) => {
  return fn(...args)
    .catch(
      (error) => {
        const [statusCode,] = parseResponse(error)
        return statusCode === 202 && retries > 1 ?
          pause(delay).then(() => insist(fn, args, retries - 1, delay))
         :
          Promise.reject(error)
      }
    );
}
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentSummary } from "../../../actions/payment";
import { Button, ChipOwnProps, Dialog, IconButton, styled, useTheme } from "@mui/material";
import SuccessPaymentDialogContent from "../../PaymentPlatform/CheckoutFormComps/SuccessPaymentDialogContent";
import PaymentPlatformProvider from "../../PaymentPlatform/PaymentPlatformProvider";
import { Info, InfoOutlined } from '@mui/icons-material/';
import Settings from "@/settings";
import { RootState } from "@/store";
import { Invoice } from "./PaymentStatus/PaymentStatus";

interface IPaymentPaidSummaryButtonProps {
  invoice: Invoice;
  paidColor?: ChipOwnProps["color"];
  children?: string | JSX.Element;
}

const MuiButtonPayment = styled(Button, {
  name: 'MuiButtonPayment'
})({});

const PaymentPaidSummaryButton = ({
  invoice,
  paidColor,
  children 
}: IPaymentPaidSummaryButtonProps) => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const { isFetching, success } = useSelector((state: RootState) => state.payment);
  const [openPaymentSummaryDialog, setOpenPaymentSummaryDialog] = useState(false);

  const handleClickOnSeePaymentSummaryButton = () => {
    setOpenPaymentSummaryDialog(true);
    dispatch(getPaymentSummary(invoice.invoice_id));
  }

  let button = null

  switch (Settings?.invoices?.paymentStatus) {
    case "Base":
      const defaultProps = theme.components?.MuiButtonPayment?.defaultProps;
      button = <MuiButtonPayment {...defaultProps} variant={'text'} onClick={handleClickOnSeePaymentSummaryButton}>
        {children}
      </MuiButtonPayment>
      break;
    case "Chiped":
      button = <IconButton color={paidColor} onClick={handleClickOnSeePaymentSummaryButton} aria-label="info-payment-summary" size="small">
        { theme.components?.MuiChipedPaymentStatus?.defaultProps?.variant === 'outlined' ?
          <InfoOutlined fontSize="inherit"/>
        :
          <Info fontSize="inherit"/>
        }
      </IconButton>
      break;
    default:
      button = null;
      break;
  }

  return <>
    {button}
    {/* The Dialog component creates a Portal, so if is not opened doesn't exists in the DOM */}
    {success &&
      <PaymentPlatformProvider onClose={() => setOpenPaymentSummaryDialog(false)} >
        <Dialog open={openPaymentSummaryDialog}>
          <SuccessPaymentDialogContent/>
        </Dialog>
      </PaymentPlatformProvider>
    }
  </>
}

export default PaymentPaidSummaryButton;

// import { AuthenticationProvider } from "./components/AuthenticationProvider";

import { Provider } from 'react-redux';
import { I18nextProvider } from "react-i18next";

import RouterProvider from './routes';
import { ThemeProvider } from '@mui/material/styles';
import SettingsProvider from './components/Settings/components/SettingsProvider';

import { i18n } from './config';

import theme from './overrides/theme';
import store from './store';


import { 
  NavigationItemsContextProvider 
} from './components/NavigationMenu/NavigationItemsContextProvider';
import API from './services/api';


const App = (props) => {
  API.init();
  
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SettingsProvider>
          <I18nextProvider i18n={i18n}> 
              <NavigationItemsContextProvider>
                  <RouterProvider />
              </NavigationItemsContextProvider>
          </I18nextProvider>
        </SettingsProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;

import { 
	ChangePasswordData, ChangePasswordResponse, IChangePassword,
} from "@/types/models/services/user/changePassword";

import {
	KnownProfileDataResponses,
	ProfileData,
	ProfileDataV1
} from "@/types/models/services/user/profileData";

import API from "./api";
import { IVersionedResponse } from "@/types/definitions/apiCalls";

enum Endpoints {
	ProfileData = "/users/",
	ChangePassword = "/user/password",
}

const changePassword = ({
	currentPassword,
	newPassword
}: IChangePassword) => {
	return new Promise<ChangePasswordResponse>(async (resolve, reject) => {
		API.put<ChangePasswordResponse, ChangePasswordData>({
			endpoint: Endpoints.ChangePassword,
			data: {
				current: currentPassword, 
				password: newPassword
			}
		})
		.then(res => {
			if(res.promise.data.status === "ERROR") {
				reject(res.promise.data);
			} else {
				resolve(res.promise.data);
			}
		})
		.catch(err => {
			reject(err);
		})
	})
}

const getProfileData = () => {

	// Returns one of the Known Profile Data Responses
	return new Promise<IVersionedResponse<KnownProfileDataResponses>>(async (resolve, reject) => {

		// Indicates all Known Profile Data Responses
		API.get<KnownProfileDataResponses>({
			endpoint: Endpoints.ProfileData,
			versions: [1, 2],
			currentVersion: 2
		})
		.then(res => {
			switch( res.version ) {
				case 2:
					resolve({data: res.promise.data as ProfileData, version: res.version});

				case 1:
					resolve({data: res.promise.data as ProfileDataV1, version: res.version});
			}
		})
		.catch(err => {
			reject(err);
		})
	})
}

export default {
	getProfileData,
	changePassword
};
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form"
import { z } from "zod";
import { zodResolver } from '@hookform/resolvers/zod';
import { cupsValid } from "../form-fields/CUPSField";
import NumericFormatCustom from "./fields/NumericFormatField";
import TextFieldReadOnly from "../TextFieldReadOnly";
import Appliance, { IAppliance } from "./Appliance";
import { APPLIANCES, ownIcons } from "./custom/config";
import { default as leadService } from "@/services/leads";
import Settings from "../../settings";
import HelpPanel from "../HelpPanel";
import Cookies from '../../utils/cookies';
import { marketingHook } from "../../overrides/hooks/power-calculator";

import {
  RadioGroup, FormControlLabel, Radio,
  Box, Typography, Grid, TextField,
  FormHelperText
} from '@mui/material';
import { Button } from '@gisce/oficina-virtual-components';
import { useTranslation } from "react-i18next";
import Result from "./result";
import { LeadPowerSubmitData } from "@/types/models/services/leads";
import { LeadContactFormData } from "../LeadContact";

type CalculadoraPotenciaFormData = Omit<LeadPowerSubmitData, "area" | "numPersones"> & {
  area: number;
  numPersones: number;
}

const CalculadoraPotencia = () => {
  const { i18n, t } = useTranslation();

  const [appliances, setAppliances] = useState<IAppliance[]>([]);
  const [cofSimul, setCofSimul] = useState(0.4);
  const [showResult, setShowResult] = useState(false);
  const [leadSent, setLeadSent] = useState(false);

  const schema = z.object({
    cups: z.string().min(1, t('common:text.required_field'))
    .refine(
      (tel) => cupsValid(tel), 
      {message: t('common:text.contractation_cups_not_valid')}
    ),
    area: z.number().positive({message: t('common:text.required_field')}),
    numPersones: z.number().positive({message: t('common:text.required_field')})
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<CalculadoraPotenciaFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      area: 0
    }
  });

  const addAppliance = (appliance: IAppliance) => {
    const newAppliances = [...appliances.filter(
      v => v.name !== appliance.name
    ), appliance];
    setAppliances(newAppliances);
  }

  const removeAppliance = (appliance: IAppliance) => {
    const newAppliances = appliances.filter(
      v => v.name !== appliance.name
    );
    setAppliances(newAppliances);
  }

  const handleChangeAppliance = (appliance: IAppliance) => {
    if (appliance.enabled) {
      addAppliance(appliance);
    } else {
      removeAppliance(appliance);
    }
  }

  const onComparatorPowerSubmit = async (formValues: CalculadoraPotenciaFormData) => {
    const leadFormSubmitValues: LeadPowerSubmitData = {
      optimalPower: Math.round(appliances.reduce((x, y) => x + (y.totalPower), 0) * 100) / 100,
      appliances: appliances,
      area: Number(formValues.area),
      numPersones: Number(formValues.numPersones),
      cofSimul: formValues.cofSimul,
      cups: formValues.cups
    }
    await leadService.sendLeadPower({cups: getValues("cups"), data: leadFormSubmitValues});
    setShowResult(true);
  }

  const leadSubmit = async (personalData: LeadContactFormData) => {
    const response = await leadService.sendLeadPower({cups: getValues("cups"), data: personalData});
    if (response.status === "OK") {
      setLeadSent(true);
    }
  }

  const handleHelpPanelClick = () => {
    if(i18n.exists('common:url.calculatorHelp')){
			window.location.href = t('common:url.calculatorHelp');
    }
  };

  useEffect(() => {
    if (Cookies.marketingConsentGiven()) {
      marketingHook();
    }
  }, []);

  const maxPower = Math.round(appliances.reduce((x, y) => x + (y.totalPower), 0) * 100) / 100;

  const areaWatchValue = watch("area");

  return (
    <div className="power-calculator">
      {!leadSent && <>
        {!showResult && 
          <form onSubmit={handleSubmit(onComparatorPowerSubmit)}>
            <Box>
              <Typography variant="h5">
                {t('common:text.lead_power_title')}
              </Typography>
            </Box>

            <Grid container spacing={3} justifyContent={'center'}>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  label="CUPS*"
                  {...register("cups")}
                  sx={{width: "100%"}}
                  error={!!errors.cups}
                  placeholder={t('common:text.contractation_cups_placeholder')}
                />
                {errors.cups?.message && 
                  <FormHelperText error>
                    {errors.cups?.message}
                  </FormHelperText>
                }
                <div className="helper-text">
                  {t('common:text.contractation_cups_helper')}
                </div>

                <Controller
                  name="area"
                  control={control}
                  render={({ field }) => <TextField
                    style={{width: '100%'}}
                    label={t('common:text.lead_power_area')}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      inputProps: {
                        suffix:" m²",
                        allowNegative: false,
                        allowedDecimalSeparators: [",", "."],
                        decimalScale: 0,
                        decimalSeparator: ",",
                        thousandSeparator: ".", 
                      }
                    }}
                    variant="standard"
                    error={!!errors.area}
                    {...field}
                  />}
                />
                {errors.area?.message && 
                  <FormHelperText error={true} id="area-field-error-text">
                    {errors.area.message}
                  </FormHelperText>
                }

                <Controller
                  name="numPersones"
                  control={control}
                  render={({ field }) => <TextField
                    style={{width: '100%'}}
                    label={t('common:text.lead_power_people')}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      inputProps: {
                        allowNegative: false,
                        allowedDecimalSeparators: [",", "."],
                        decimalScale: 0,
                        decimalSeparator: ",",
                        thousandSeparator: ".", 
                      }
                    }}
                    variant="standard"
                    error={!!errors.numPersones}
                    {...field}
                  />}
                />
                {errors.numPersones?.message && 
                  <FormHelperText error>
                    {errors.numPersones?.message}
                  </FormHelperText>
                }

              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                {/* TODO: Moure aquest radio button a dins el form */}
                <TextFieldReadOnly label={t('common:text.lead_power_cofsimul')}>
                  <RadioGroup
                    name="cofSimul"
                    defaultValue={0.4}
                    onChange={(event) =>
                      setCofSimul(Number(event.target.value))
                    }
                    value={cofSimul}
                  >
                    <FormControlLabel
                      value={0.25}
                      control={<Radio />}
                      label={t('common:text.lead_power_cofsimul_pocs')}
                    />
                    <FormControlLabel
                      value={0.4}
                      control={<Radio />}
                      label={t('common:text.lead_power_cofsimul_alguns')}
                    />
                    <FormControlLabel
                      value={0.6}
                      control={<Radio />}
                      label={t('common:text.lead_power_cofsimul_molts')}
                    />
                  </RadioGroup>
                </TextFieldReadOnly>
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                {Settings?.powerCalculator?.sideContact &&
                  <HelpPanel
                    onClick={handleHelpPanelClick}
                    sx={{mt: "2rem"}}
                  />
                }
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h5">
                    {t('common:text.lead_power_appliances')}
                  </Typography>
                </Box>
                <TextFieldReadOnly label={t('common:text.lead_power_appliances_question')}>
                  <div
                    style={{
                      fontStyle: "italic",
                      fontSize: 12,
                      color: "rgba(0, 0, 0, 0.3)",
                      marginLeft: 10,
                    }}
                  >
                    {t('common:text.lead_power_appliances_question_hint')}
                  </div>
                </TextFieldReadOnly>
                <Grid container spacing={3}>
                  {APPLIANCES.map((appliance, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                      <Appliance
                        {...appliance}
                        cofSimul={cofSimul}
                        area={Number(areaWatchValue ?? 0)}
                        onChange={handleChangeAppliance}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Button
                  type="submit"
                  disabled={isSubmitting || !isDirty}
                  variant={"contained"}
                  sx={{mt: 2}}
                >
                  {t('common:text.lead_power_calculate')}
                </Button>
              </Grid>
            </Grid>
          </form>
        }
        {showResult && <Result leadSubmit={leadSubmit} maxPower={maxPower}/>}
      </>}
      {leadSent &&
        <Box>
          <Typography variant="h4">
            {t('common:text.lead_thank_you')}
          </Typography>
        </Box>
      }
      {!ownIcons && (
        <footer style={{ marginTop: 100 }}>
          <p>
            Icones creats per
            <a href="https://www.flaticon.com/authors/pause08">pause08</a>,
            <a href="https://www.flaticon.com/authors/smashicons">
              Smashicons
            </a>
            ,<a href="https://www.flaticon.com/authors/monkik">monkik</a>,
            <a href="https://www.flaticon.com/authors/freepik">Freepik</a>,
            <a href="https://www.flaticon.com/authors/photo3idea-studio">
              photo3idea_studio
            </a>
            ,
            <a href="https://www.flaticon.com/authors/ultimatearm">
              ultimatearm
            </a>
            ,<a href="https://www.flaticon.com/authors/xnimrodx">xnimrodx</a>,
            de <a href="http://www.flaticon.com">www.flaticon.com</a>
          </p>
        </footer>
      )}
    </div>
  );
}


export default CalculadoraPotencia;

import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useElements } from '@stripe/react-stripe-js';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PaymentPlatformContext } from '../PaymentPlatformProvider';

/**
 * UI Component
 * 
 * Responsible for rendering the dialog content of payment form using `dialogContent` component.
 * 
 * - Responsible for taking a onSubmit and onCancel props and call the event when submit form
 */
const FillPaymentDialogContent = ({ onSubmit, dialogContent }) => {
  const { i18n } = useTranslation();
  const { isFetching } = useSelector((state) => state.payment);
  const [elementsWithErrors, setElementsWithErrors] = useState({});
  const elements = useElements();
  const { onClose } = useContext(PaymentPlatformContext);

  useEffect(() => {
    let card = undefined;
    if (elements) {
      // card = elements.getElement("card");
      // for (let domIdentifier of ["cardNumber", "cardExpiry", "cardCvc"]) {
      for (let card of elements._elements) {
        setElementsWithErrors((prev) => {
          prev[card["_componentName"]] = "empty";
          return { ...prev };
        });
        card.on("change", (element) => {
          let errorValue = false;
          errorValue = element.empty || element.error || !element.complete;
          if (errorValue) {
            setElementsWithErrors((prev) => {
              prev[element["elementType"]] = errorValue;
              return { ...prev };
            });
          } else {
            setElementsWithErrors((prev) => {
              delete prev[element["elementType"]];
              return { ...prev };
            });
          }
        });
      }
    }
  }, [elements]);

  return (
    <>
      <DialogTitle>
        {i18n.t('common:text.payment_form_details')}
      </DialogTitle>
      <DialogContent sx={{ '.MuiDialogContent-root&.MuiDialogContent-root': { pt: 1 } }}>
        {dialogContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isFetching}>
          {i18n.t('common:text.payment_form_cancel')}
        </Button>
        <Button onClick={onSubmit} color="primary" variant="contained" disabled={isFetching || Object.keys(elementsWithErrors).length > 0}>
          {i18n.t('common:text.payment_form_just_pay')}
        </Button>
      </DialogActions>
    </>
  )
}


export default FillPaymentDialogContent;

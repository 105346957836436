import { useState } from 'react';

import ContractsListElectricity from './ContractsListElectricity';
import ContractsListGas from './ContractsListGas';
import { features } from "../../settings";

import { Tabs, Tab, Box, Typography, Stack, colors } from '@mui/material';
import GasIcon from '../../assets/GasIcon';
import LightIcon from '../../assets/LightIcon';
import { useTranslation } from 'react-i18next';
import { ContentHeader } from '../ContentHeader';
import { Button } from '@gisce/oficina-virtual-components';
import { Add } from '@mui/icons-material';

const ContractsTabs = () => {
  const { t } = useTranslation();
  const [contractType, setContractType] = useState("electricity");

  const handleChangeContractType = (_event: React.SyntheticEvent, value: any) => {
    setContractType(value);
  };

  return <Box sx={{ width: '100%', mt: -1}}>
    <Stack spacing={3}>

      <Stack spacing={1}>
        <ContentHeader
          title={t('common:text.contracts_list_title')}
        />
        <Typography variant='subtitle1'>
          {t('common:text.contracts_list_subtitle')}
        </Typography>
      </Stack>

      <Box>

        {features.displayTypes.length > 1 ? <Stack sx={{mt: -2}} spacing={3}>
          <Tabs 
            value={contractType} 
            onChange={handleChangeContractType}
            sx={{borderBottom: `1px solid ${colors.grey[300]}`}}
          >
            {features.displayTypes.includes('electricity') && 
              <Tab 
                label={t("common:text.contracts_tab_title_electricty")} 
                value="electricity" 
                icon={<LightIcon />} 
                iconPosition="start"
              />
            }

            {features.displayTypes.includes('gas') && 
              <Tab 
                label={t("common:text.contracts_tab_title_gas")} 
                value="gas" 
                icon={<GasIcon />} 
                iconPosition="start"
              />
            }
          </Tabs>
          {features.displayTypes.includes('electricity') && 
            <div hidden={contractType !== 'electricity'}>
              <ContractsListElectricity/>
            </div>
          }
          {features.displayTypes.includes('gas') && 
            <div hidden={contractType !== 'gas'}>
              <ContractsListGas/>
            </div>
          }
        </Stack> : <>
          {features.displayTypes[0] === 'electricity' && 
            <ContractsListElectricity/>
          }
          {features.displayTypes[0] === 'gas' && 
            <ContractsListGas/>
          }
        </>
        }
      </Box>
    </Stack>
  </Box>;
}

export default ContractsTabs;
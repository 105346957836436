import { Button, CircularProgress } from "@mui/material";
import { GetApp } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { downloadTransactionReport } from '../../actions/payment';

/*
 * Feature component
 *
 * Responsible to render the download transaction report button.
 *
 * - Responsible to handle the downloadTransactionReport reducer action.
*/
 const DownloadTransactionReportButton = ({invoiceId}) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { downloadingReport } = useSelector((state) => state.payment);

  return (
    <>
        <Button
            title={"Download payment transaction"}
            color={"primary"}
            variant={"contained"}
            disabled={downloadingReport}
            onClick={() => dispatch(downloadTransactionReport(invoiceId))}
            >
            {downloadingReport ? <CircularProgress size={25} /> : <GetApp />}
            { i18n.t("common:text.transaction_download_receipt") }
        </Button>
    </>
  )
}


export default DownloadTransactionReportButton;
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { decodeJwt } from "jose";
import { default as newsletterService } from "@/services/newsletter";
import {
  NewsletterRead,
  OfuscatedId,
  SubscriberEmails,
  SubscriberId,
} from "@/types/models/services/newsletter";
import { NewsletterJWT } from "@/types/models/services/newsletter";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { useSettingsContext } from "../Settings/hooks/useSettingsContext";

type RequestState = "Idle" | "Fetching" | "Success" | "Error";

export interface NewsletterPageState {
    subscriptorRemovedSuccess: boolean;
    changeMailSuccess: boolean;
    changeMailError: boolean;
    jwtError: boolean;
    fetchNewsletters: RequestState;
    fetchSubscriberData: RequestState;
    submitSubcriptions: RequestState;
}

export type SelectedNewsletters = NewsletterRead["id"][];
export const useNewsletterPage = () => {
  const Settings = useSettingsContext()
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
	const profile = useSelector((state: RootState) => state.profile);
  const [subscriberId, setSubscriberId] = useState<OfuscatedId | SubscriberId | null>(null);
  const [emails, setEmails] = useState("");
  const [newsletters, setNewsletters] = useState<NewsletterRead[]>([]);
  const [selectedNewsletters, setSelectedNewsletters] = useState<SelectedNewsletters>([]);
  const [state, setState] = useState<NewsletterPageState>({
    subscriptorRemovedSuccess: false,
    changeMailSuccess: false,
    changeMailError: false,
    jwtError: false,
    fetchNewsletters: "Idle",
    fetchSubscriberData: "Idle",
    submitSubcriptions: "Idle"
  });

  const getJwtFromUrl = (): string | null => {
    const params = new URLSearchParams(location.search);
    return params.get("params");
  };

  useEffect(() => {
    // Check if a JWT exists in the URL
    const jwt = getJwtFromUrl();
    if (jwt && !subscriberId) {
      try {
        const decoded = decodeJwt<NewsletterJWT>(jwt);
        if (decoded.subscriptor_id) {
          fetchSubscriber(decoded.subscriptor_id);
        } else if (Settings?.newsletter?.jwtParamsRequired) {
          setState(prev => ({...prev, jwtError: true}));
        }
      } catch (error) {
        console.error("Error decoding the JWT:", error);
        setState(prev => ({...prev, jwtError: true}));
      }
    } else if (Settings?.newsletter?.jwtParamsRequired) {
      setState(prev => ({...prev, jwtError: true}));

    // Check if a newsletter URL exists in profile data
    } else if (profile?.data?.newsletter_url && !emails) {
      navigateManageNewsletters();

    // Check if an email exists in profile data
    } else if (profile?.data?.email && !emails) {
      handleMailChangeConfirmed(profile.data.email);
    }
  }, [location, profile?.data?.email, profile?.data?.newsletter_url]);

  useEffect(() => {
    if ((typeof subscriberId !== "number" || !subscriberId) && !emails) {
      return;
    }
    fetchNewsletters();
  }, [subscriberId, emails]);

  const fetchSubscriber = (subscriptorId:OfuscatedId) => {
    setState(prev => ({...prev, fetchSubscriberData: "Fetching"}));
    newsletterService
    .fetchSubscriber(subscriptorId)
    .then((data) => {
      if (data) {
        setEmails(data.emails);
        setSubscriberId(data.id);
        setState(prev => ({...prev, fetchSubscriberData: "Error"}));
      } else {
        setState(prev => ({...prev, fetchSubscriberData: "Success"}));
        setState(prev => ({...prev, jwtError: true}));
      }
    });
  }

  const fetchNewsletters = () => {
    setState(prev => ({...prev, fetchNewsletters: "Fetching"}));
    newsletterService
      .fetchNewsletters(subscriberId !== null ? subscriberId : emails)
      .then((data) => {
        setNewsletters(data);
        setState(prev => ({...prev, fetchNewsletters: "Success"}));
        setSelectedNewsletters(data.filter((n) => n.subscribed).map((e) => e.id));
        if (data.length && data[0].subscriberId !== null) {
          setSubscriberId(data[0].subscriberId);
        }
      })
      .catch((err) => {
        setState(prev => ({...prev, fetchNewsletters: "Error"}));
        console.error(err.message);
      });
  }

  const clearState = () => {
    setState({
      subscriptorRemovedSuccess: false,
      changeMailSuccess: false,
      changeMailError: false,
      jwtError: false,
      fetchNewsletters: "Idle",
      fetchSubscriberData: "Idle",
      submitSubcriptions: "Idle"
    })
  }

  const handleMailChangeConfirmed = (newEmail: SubscriberEmails) => {
    clearState();
    setState(prev => ({...prev, subscriptorRemovedSuccess: false}));
    setEmails(newEmail);
  }

  const handleUpdateEmail = (newEmail: SubscriberEmails) => {
    if (!subscriberId) {
      console.error("The subscriberId is not defined");
      return;
    }
    if (typeof subscriberId === "string") {
      console.error("The subscriberId is ofuscated");
      return;
    }
    clearState();

    try {
      newsletterService
        .setNewsletterSubscriptorMails(subscriberId, newEmail)
        .then((data) => {
          if (data) {
            setEmails(newEmail);
            setState(prev => ({...prev, changeMailError: false}));
            setState(prev => ({...prev, changeMailSuccess: true}));
          } else {
            setState(prev => ({...prev, changeMailError: true}));
            setState(prev => ({...prev, changeMailSuccess: false}));
          }
        })
        .catch(() => {
          setState(prev => ({...prev, changeMailError: true}));
          setState(prev => ({...prev, changeMailSuccess: false}));
        });
    } catch {
      setState(prev => ({...prev, changeMailError: true}));
      setState(prev => ({...prev, changeMailSuccess: false}));
    }
  };

  const handleSubscribe = () => {
    setState(prev => ({...prev, submitSubcriptions: "Fetching"}));
    try {
      // clearState();
      let subIdentifier = subscriberId !== null ? subscriberId : emails;
      newsletterService
        .setActiveNewsletterSubscriptions(subIdentifier, selectedNewsletters)
        .then((data) => {
          setState(prev => ({...prev, submitSubcriptions: "Success"}));
          if (data === "removed") {
              setSubscriberId(null);
              setNewsletters([]);
              setEmails("");
              setSelectedNewsletters([]);
              setState(prev => ({...prev, subscriptorRemovedSuccess: true}));
          } else {
            setNewsletters(data); // Carregar les dades transformades directament
            setSelectedNewsletters(
              data.filter((n) => n.subscribed).map((e) => e.id),
            );

            if (!subscriberId && data.length && data[0].subscriberId) {
              // Special case: a new subscriber must be able to edit their email once created
              setSubscriberId(data[0].subscriberId);
            }
          }
        })
        .catch(() => {
          setState(prev => ({...prev, submitSubcriptions: "Error"}));
        });
    } catch {
      setState(prev => ({...prev, submitSubcriptions: "Error"}));
    }
  };

  const navigateManageNewsletters = () => {
    let newsletterParams = "";
    if (profile?.data?.newsletter_url) {
      newsletterParams = profile.data.newsletter_url.replace(/.*override_this_path/i, "");
    }
    
    navigate(t("common:url.newsletter_params", { params: newsletterParams }));
  }

  return {
    subscriberId, emails, newsletters, selectedNewsletters, state, handleMailChangeConfirmed, handleUpdateEmail, handleSubscribe, setSelectedNewsletters, navigateManageNewsletters
  }
};

export default useNewsletterPage;

import { 
    FETCH_SETTINGS_REQUEST, 
    RECEIVE_SETTINGS, 
    RECEIVE_SETTINGS_KO 
} from '../../../constants/index'
import { Settings } from '../types/settings';

export function receiveSettings(settings: Settings) {
    return {
        type: RECEIVE_SETTINGS,
        payload: {
            data: settings
        },
    };
}

// TODO
// export function receiveSettingsError(error) {
//     return {
//         type: RECEIVE_SETTINGS_KO,
//         payload: {
//             data,
//         },
//     };
// }

export function fetchSettingsRequest() {
    return {
        type: FETCH_SETTINGS_REQUEST,
    };
}
import { SelectCardGroup, SelectCard } from "@gisce/oficina-virtual-components";

import { LoadingAnimation } from '../LoadingAnimation';
import { RichContent } from "@gisce/oficina-virtual-components";
import Settings from "../../settings";
import { FC, useEffect } from "react";
import { Product } from "@/types/models/services/products";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";


interface IElectricityProductsSelectProps {
  loading: boolean;
  availableProducts: Product[];
  input: {
    onChange: (...event: any[]) => void;
    value: number;
  }
}

const ElectricityProductSelect: FC<IElectricityProductsSelectProps> = ({
  loading,
  availableProducts,
  input
}) => {
  const { t } = useTranslation();

  return loading ? (
    <LoadingAnimation />
  ) : (
    <>
      <SelectCardGroup 
        container 
        spacing={3}
        onChange={input.onChange}
        value={input.value}
      >
        {availableProducts.map(
          product => <SelectCard
            xs={12}
            md={6}
            key={product.id}
            value={product.id}
            name={product.name}
            description={product.ov_description}
            moreInformation={{
              link: product.ov_link,
              label: "+ info"
            }}
          />
        )}
      </SelectCardGroup>
      { availableProducts.length === 0 && (
        <Alert severity="warning" sx={{mt: 3}}>
          <RichContent
            content={t('common:text.contractation_no_available_tariffs',
              {
                phone: Settings.organization.phone_crm,
                phoneLink: Settings.organization.phone_crm.replace(/\s/g, '')
              }
            )}
          />         
        </Alert>
      )}
    </>
  );
}

export default ElectricityProductSelect;
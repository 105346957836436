import {
	FeatureFlagError,
	FeatureFlags,
	FetchFeatureFlagsResponse,
} from "@/types/models/services/feature_flags";
import API from "./api";
import { AxiosError } from "axios";

// Constants
const FEATURE_FLAGS_PATH = `/feature_flags/`;

// Type guard
const isFeatureFlags = (flags: any): flags is FeatureFlags => {
	return (
		typeof flags === "object" &&
		flags !== null &&
		typeof flags["coupons.coupon"] === "boolean" &&
		typeof flags["crm.case"] === "boolean"
	);
};

// Services
const fetchFeatureFlags = async (): Promise<FeatureFlags> => {
	try {
		const response = await API.get<FetchFeatureFlagsResponse>({
			endpoint:	FEATURE_FLAGS_PATH
		});

		// Validate response
		if (!isFeatureFlags(response.promise.data.feature_flags)) {
			throw new Error("Invalid feature flags response format");
		}

		return response.promise.data.feature_flags;
	} catch (error) {
		const flagError: FeatureFlagError = new Error(
			error instanceof Error ? error.message : "Failed to fetch feature flags",
		);

		if (error instanceof AxiosError && error.response) {
			flagError.code = error.response.status.toString();
			flagError.response = error.response;
		}

		throw flagError;
	}
};


export default {
	fetchFeatureFlags,
};

import { Dialog } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import StripePaymentProvider from "./stripe/StripePaymentProvider";
import PaymentPlatformProvider, { PaymentPlatformContext } from "./PaymentPlatformProvider";
import { useSelector } from "react-redux";


const PaymentPlaformDialog = ({open, platformName}) => {
  
  const { isPaymentPlatformAvailable,  } = useContext(PaymentPlatformContext);

  const drawedPaymentIntent = {
    "stripe": <StripePaymentProvider/>,
    "monei": null
  };

  let drawedDialog = null;
  if (isPaymentPlatformAvailable()) {
    drawedDialog =  (
      <Dialog open={open} fullWidth maxWidth="sm" >
        {drawedPaymentIntent[platformName]}
      </Dialog>
    )
  }
  return drawedDialog;
}

/*
 * Feature component
 *
 * Responsible for rendering the payment dialog.
 *
 * - Handles the retrieval of availablePaymentPlatforms reducer actions.
 * - Transmits data to the paymentPlatforms components.
 * - Hides the dialog if the platformName is not available.
*/
const PayDialog = (props) => {
  const payingItemId = useSelector((state) => state.payment.payingItemId);
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    if (payingItemId) {
      setOpen(true);
    }
  }, [payingItemId])

  return (
    <PaymentPlatformProvider {...props} onClose={() => setOpen(false)}>
      <PaymentPlaformDialog open={open} platformName={props["platformName"]}/>
    </PaymentPlatformProvider>
  )

};


export default PayDialog;

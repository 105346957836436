import { Close } from "@mui/icons-material";
import { IconButton, Snackbar, SnackbarCloseReason } from "@mui/material";
import { useState } from "react";

interface INotificationProps {
  message: string;
  beginOpen: boolean;
  expirationTime: number;
}

export const Notification = ({
  message = '',
  beginOpen = false,
  expirationTime = 15
}: INotificationProps) =>  {
  const [open, setOpen] = useState(beginOpen);

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return message != null && (
    <Snackbar
      open={open}
      className="notification"
      message={message}
      autoHideDuration={expirationTime}
      action={<IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>}
    />
  )
}
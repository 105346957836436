import { Box, Button, ChipOwnProps, Dialog, DialogActions, DialogContent, IconButton, styled, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Info, InfoOutlined } from '@mui/icons-material/';
import Settings from "@/settings";
import { Invoice } from "./PaymentStatus/PaymentStatus";

interface IPaymentPendingStateHistoryButtonProps {
  invoice: Invoice;
  paidColor?: ChipOwnProps["color"];
  children?: string | JSX.Element;
}

const MuiButtonPayment = styled(Button, {
    name: 'MuiButtonPayment'
  })({});

const PaymentPendingStateHistoryButton = ({
    invoice,
    paidColor,
    children
}: IPaymentPendingStateHistoryButtonProps) => {
    const theme = useTheme();
    const { i18n } = useTranslation();
    const [ openPaymentPendingStateHistoryDialog, setOpenPaymentPendingStateHistoryDialog] = useState(false);

    const handleCloseDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenPaymentPendingStateHistoryDialog(false);
    }

    const hasOvDescription = invoice?.pending_state_history.some(i =>
        i.pending_state && i.pending_state.ov_description !== undefined
    );

    let drawedTableRows: JSX.Element[] = [];
    if (hasOvDescription) {
        drawedTableRows = invoice.pending_state_history.map((i, index) => {
            return (
                <tr key={index}>
                    <td>{i18n.t(i.change_date)}</td>
                    <td>{i.pending_state.ov_description}</td>
                </tr>
            );
        });
    }
    
    let button = null

    switch (Settings?.invoices?.paymentStatus) {
      case "Base":
        const defaultProps = theme.components?.MuiButtonPayment?.defaultProps;
        button = <MuiButtonPayment onClick={() => setOpenPaymentPendingStateHistoryDialog(true)} {...defaultProps} variant={'text'} style={{padding: 0}} >
          {children}
        </MuiButtonPayment>
        break;
      case "Chiped":
        button = <IconButton color={paidColor} onClick={() => setOpenPaymentPendingStateHistoryDialog(true)} aria-label="info-payment-pending-state" size="small">
          { theme.components?.MuiChipedPaymentStatus?.defaultProps?.variant === 'outlined' ?
            <InfoOutlined fontSize="inherit"/>
            :
            <Info fontSize="inherit"/>
            }
        </IconButton>
        break;
      default:
        button = null;
        break;
    }

    if (drawedTableRows.length > 0) {
        return <>
            {button}
            <Dialog open={openPaymentPendingStateHistoryDialog} onClose={handleCloseDialog} scroll={"body"}>
                <DialogContent>
                    <Box mb={2}>
                        <Typography variant={"h4"}>{i18n.t('common:text.invoices_dialog_title')}</Typography>
                    </Box>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>{i18n.t('common:text.invoices_dialog_table_date')}</th>
                            <th>{i18n.t('common:text.invoices_dialog_table_description')}</th>
                        </tr>
                        </thead>
                        <tbody>
                            { drawedTableRows }
                        </tbody>

                    </table>
                </DialogContent>
                <DialogActions>
                    <Button color={"primary"} variant={"text"} onClick={handleCloseDialog} >
                        {i18n.t('common:text.invoices_dialog_close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    }
    else return null;
}

export default PaymentPendingStateHistoryButton;
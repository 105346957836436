import { useTranslation } from "react-i18next";
import Settings from "@/settings";
import PaymentPaidSummaryButton from "../../PaymentPaidSummaryButton";
import PaymentPendingStateHistoryButton from "../../PaymentPendingStateHistoryButton";
import { Invoice } from "../PaymentStatus";

const BasePaymentStatus = ({invoice}: {invoice: Invoice}) => {
  const { i18n } = useTranslation();
  let paid = null;

  if (invoice.paid === "paid_without_transaction") {
    paid = <strong>{i18n.t('common:text.invoice_paid')}</strong>;
  }
  else if (invoice.paid === "manual_verification_required") {
    paid = (
      <PaymentPaidSummaryButton invoice={invoice}>
        {i18n.t('common:text.invoice_paid')}
      </PaymentPaidSummaryButton>
    )
  }
  else if (invoice.paid === "paid") {
    if (invoice.rectificative_type === "A" || invoice.rectificative_type === "B") {
      paid = <strong>{i18n.t('common:text.invoice_refunded')}</strong>
    }
    else if (!Settings.invoices.enablePayment) {
      paid = <strong>{i18n.t('common:text.invoice_paid')}</strong>;
    }
    else (
      paid = (
        <PaymentPaidSummaryButton invoice={invoice}>
          {i18n.t('common:text.invoice_paid')}
        </PaymentPaidSummaryButton>
      )
    )
  }
  else if (invoice.paid === "unpaid"){
    paid = <strong>{i18n.t('common:text.invoice_not_paid')}</strong>;
    if (invoice.amount_pending) {
      if (invoice.amount_pending != invoice.amount_total) {
        paid = <strong>{i18n.t('common:text.invoices_parcial_payment')}</strong>;
      }
      // We enable a link to show the pending state history only if there is more than one item to show.
      if (invoice.pending_state_history && invoice.pending_state_history.length > 1) {
        paid = (
          <PaymentPendingStateHistoryButton invoice={invoice} >
            { paid }
          </PaymentPendingStateHistoryButton>
        )
      }
    }
  }
  else if (invoice.paid === "pending_remesa") {
    paid = <strong>{i18n.t('common:text.invoice_pending_remesa')}</strong>;
    if (invoice.rectificative_type === "A" || invoice.rectificative_type === "B") {
      paid = <strong>{i18n.t('common:text.invoice_pending_refunded')}</strong>;
    }
  }
  else if (invoice.paid === "pending_transfer") {
    paid = <strong>{i18n.t('common:text.invoice_pending_transfer')}</strong>;
    if (invoice.rectificative_type === "A" || invoice.rectificative_type === "B") {
      paid = <strong>{i18n.t('common:text.invoice_pending_refunded')}</strong>;
    }
  }

  return paid;
}

export default BasePaymentStatus
import { ChipOwnProps } from "@mui/material";
import { Invoice } from "../../PaymentStatus";

const ChipedPaymentStatusStyle = (invoice: Invoice) => {
  let paidColor : ChipOwnProps["color"];

  switch (invoice?.paid) {
    case "pending_remesa":
    case "pending_transfer":
      paidColor = "warning";
      break;
    case "paid":
    case "paid_without_transaction":
    case "manual_verification_required":
      paidColor = "success";
      break;
    case "unpaid":
      paidColor = "error";
      break;
    default:
      paidColor = undefined;
      break;
  }

  return paidColor;
}

export default ChipedPaymentStatusStyle
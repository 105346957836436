import {
  UserProfileMenu as UserProfileMenuComponent 
} from '@gisce/oficina-virtual-components';
import useAuth from '../../hooks/useAuth';

import {
	VpnKey,
	Email,
	Logout
} from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Settings from '@/settings';
import useNewsletterPage from '../Newsletter/useNewsletterPage';
import { UserInfo } from './UserInfo';

export const UserProfileMenu = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
  const { navigateManageNewsletters } = useNewsletterPage();
  const { logout } = useAuth();

	const navigateChangePassword = () => {
		if (Settings.features?.oidc) {
			window.location.href = Settings.oidc.changePasswordUrl;
		} else {
			navigate(t('common:url.changePassword'))
		}
	}

  return (
    <UserProfileMenuComponent 
      headerComponent={<UserInfo />} 
      actions={[
        {
          label: t("common:text.change_password_view_title"),
          icon: <VpnKey />,
          onClick: () => navigateChangePassword()
        },
        (Settings?.newsletter?.enabled && {
          label: t("common:text.newsletter_manage"),
          icon: <Email />,
          onClick: () => navigateManageNewsletters()
        }),
        {
          label: t("common:text.logout_title"),
          icon: <Logout />,
          onClick: () => logout(),
        }
      ]}
    />
  );
}
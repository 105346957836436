import { AccessTime, Done, Payment, MoneyOff } from '@mui/icons-material/';
import { Invoice } from '../../PaymentStatus';

const ChipedPaymentStatusIcon = (invoice: Invoice) => {
  switch (invoice?.paid) {
    case "pending_remesa":
      return <AccessTime />
    case "pending_transfer":
      if (invoice?.rectificative_type === "A" || invoice?.rectificative_type === "B") {
        return <AccessTime />
      }
      return <Payment />
    case "paid":
    case "paid_without_transaction":
    case "manual_verification_required":
      return <Done />
    case "unpaid":
      return <MoneyOff />
    default:
      return;
  }
}

export default ChipedPaymentStatusIcon
import { useNavigate, To } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRedirect, setRedirected } from "@/actions/redirect";
import { ContractActions, InvoiceActions } from "@/types/models/hooks";
import { useTranslation } from "react-i18next";
import Settings from "@/settings";
import { RootState } from "@/store";
import { useEffect, useRef } from "react";

interface IRedirectActionProps {
	initalRedirect?: boolean; 
	isAuthenticated?: boolean;
}

const useRedirectActions = ({
	initalRedirect = true, 
	isAuthenticated = false
}: IRedirectActionProps ) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		redirect, redirectSearchParams, hasBeenRedirected
	} = useSelector((store: RootState) => store.auth);

	const beginContractAction = (type: ContractActions) => {
    dispatch(setRedirect('common:url.contracts', type));
		if (initalRedirect) {
			navigate(t('common:url.contracts'));
		}
  }

  const beginInvoiceAction = (type: InvoiceActions) => {
    switch( type ) {
      case "payOnline":
				const paymentURL = Settings?.invoices?.paymentURL as string | undefined;
        if (paymentURL) {
					window.open(paymentURL, '_blank'); 
					break;
        }
      default:
        dispatch(setRedirect('common:url.invoices', type));
				if (initalRedirect) {
					navigate(t('common:url.invoices'));
				}
        break;
    }
  }

	return {
		beginContractAction,
		beginInvoiceAction
	}
}

export default useRedirectActions;
import Settings from "../settings.yml";

export default Settings;

export const organization = Settings.organization;

/*
  Legacy settings
   
  This will be deprecated and removed soon! Instead, exported Settings should be used.
*/
export const features = Settings.features;

import Settings from "@/settings";
import ChipedPaymentStatus from "./ChipedPaymentStatus/ChipedPaymentStatus";
import BasePaymentStatus from "./BasePaymentStatus/BasePaymentStatus";

export type Invoice = {
  rectificative_type?: string,
  paid?: string,
  amount_pending?: number,
  amount_total?: number,
  invoice_id: number,
  pending_state_history: Array<{
    change_date: string,
    pending_state: {
      weight?: number,
      ov_description?: string,
    },
  }>
}

interface IPaymentStatusProps {
  invoice: Invoice
}

const PaymentStatus = ({
  invoice
}: IPaymentStatusProps) => {
  let paidStatus = null;
  
  switch (Settings?.invoices?.paymentStatus) {
    case "Base":
      paidStatus = <BasePaymentStatus invoice={invoice} />;
      break;
    case "Chiped":
      paidStatus = <ChipedPaymentStatus invoice={invoice} />;
      break;
    default:
      paidStatus = null;
      break;
  }

  return paidStatus;
}

export default PaymentStatus;

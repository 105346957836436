import { RECEIVE_SETTINGS, FETCH_SETTINGS_REQUEST, RECEIVE_SETTINGS_KO } from '../constants'
import { createReducer } from '../utils/misc'

const initialState = {
    data: null,
    isFetching: false,
    loaded: false,
    error: false
};

export default createReducer(initialState, {
    [RECEIVE_SETTINGS]: (state, payload) =>
        Object.assign({}, state, {
            data: payload.data,
            isFetching: false,
            loaded: true
        }),
    [FETCH_SETTINGS_REQUEST]: (state) =>
        Object.assign({}, state, {
            isFetching: true,
        }),
    [RECEIVE_SETTINGS_KO]: (state) =>
        Object.assign({}, state, {
            isFetching: false,
            loaded: true,
            error: true,
        }),

});

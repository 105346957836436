import API from "@/services/api";
import { FetchSettingsResponse } from "../types/settings";

enum Endpoints {
	FetchSettings = "/ov_customize/ov_settings"
}

const fetchSettings = () => {
	return new Promise<FetchSettingsResponse>(async (resolve, reject) => {
		API.get<FetchSettingsResponse>({
			endpoint: Endpoints.FetchSettings,
      versions: [2]
		})
		.then(res => {
			resolve(res.promise.data);
		})
		.catch(err => {
			reject(err);
		})
	})
}

export default {
	fetchSettings
};
import { FC } from "react";
import Settings from "@/settings";
import TabGroups from "./Variants/TabGroups/TabGroups";
import RedirectInvoices from "./Variants/RedirectInvoices";
import Dashboard from "./Variants/Dashboard";

const Home: FC = () => {
  switch(Settings?.home?.type) {
    case 'tabGroups':
      return <TabGroups />
    case 'dashboard':
      return <Dashboard />
    default:
      return <RedirectInvoices />
  }
}

export default Home;

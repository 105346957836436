import { RootState } from "@/store"
import { ProfileData, ProfileDataV1 } from "@/types/models/services";
import { UserInfo as UserInfoComponent } from "@gisce/oficina-virtual-components"
import { useSelector } from "react-redux"

export const UserInfo = () => {
	const {data: untypedData, isFetching} = useSelector((state: RootState) => state.profile);
	
	const profileData = untypedData && (() => {
		switch (untypedData.version) {
			case 2:
				return (untypedData.data as ProfileData);

			case 1:
				return (untypedData.data as ProfileDataV1).result;
		}
	})();

	return profileData ? <UserInfoComponent 
		avatarPosition="left" 
		email={profileData.email}
		name={profileData.name}
		loading={isFetching}
	/> : <></>
}
import { 
	SET_REDIRECT, 
	SET_REDIRECTED,
	SET_REDIRECTED_ACTION_EXECUTED
} from "@/constants";

const setRedirect = (redirect: string, redirectAction: string) => {
	return {
		type: SET_REDIRECT,
		payload: {
			redirect,
			redirectAction,
			hasBeenRedirected: false
		}
	}
}

const setRedirected = () => {
	return {
		type: SET_REDIRECTED
	}
}

const setRedirectedActionExecuted = () => {
	return {
		type: SET_REDIRECTED_ACTION_EXECUTED
	}
}

export {
	setRedirect,
	setRedirected,
	setRedirectedActionExecuted
}
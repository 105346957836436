import { loginCredentials } from "./loginCredentials";
import { loginToken } from "./loginToken";
import { loginClientId } from "./loginClientId";
import { loginOpenId, redirectOpenId } from "./loginOpenId";
import { loginRefresh } from "./refresh";
import { loginNotMade } from "./notLoggedIn";
import { recoverUser } from "./recoverUser";
import { registerUser } from "./register";
import { logout } from "./logout";
import { SET_REDIRECTED, CLEAR_ERROR } from "@/constants";

const clearError = () => {
	return {
		type: CLEAR_ERROR
	}
}

export default {
	loginCredentials,
	loginToken,
	loginClientId,
	redirectOpenId,
	loginOpenId,
	loginRefresh,
	loginNotMade,
	recoverUser,
	registerUser,
	clearError,
	logout
}
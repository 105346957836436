import useI18n from "@/hooks/useI18n";
import { ListGroup, ListItem } from "@gisce/oficina-virtual-components"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";
import Settings from "../../../settings";
import { useSelector } from "react-redux";
import {
  Person,
  PersonAdd,
  AddBox,
  Message,
  MapsHomeWork,
  Receipt,
  Equalizer,
  Apps
} from "@mui/icons-material";
import { RootState } from "@/store";
import { useNavigationItemsContext } from "../useNavigationItemsContext";
import useRedirectActions from "@/hooks/useRedirectActions";

export const GroupedSideBar = () => {
  const { t } = useTranslation();
  const { getContractationURL } = useI18n();
  const navigate = useNavigate();
  const { currentPath } = useNavigationItemsContext();

  const { 
    isAuthenticated, redirectAction, redirectActionExecuted
  } = useSelector((state: RootState) => state.auth);

  const { 
    beginContractAction, beginInvoiceAction 
  } = useRedirectActions({isAuthenticated});

  const ContractListGroup = <ListGroup
    icon={<MapsHomeWork />}
    titleLabel={t('common:text.altHome_contract_title')}
    startOpen={
      ( location.pathname === t('common:url.contracts') 
        || currentPath == 'common:url.contracts_detail')
        && ( redirectAction != "consumption" || redirectActionExecuted )}
  >
    <ListItem
      label={t('common:text.altHome_contract_create')}
      onClick={() => navigate(getContractationURL())}
      selected={location.pathname === getContractationURL()}
    />
    <ListItem
      label={t('common:text.altHome_contract_view')}
      onClick={() => beginContractAction("view")}
      selected={
        ( location.pathname === t('common:url.contracts') 
          || currentPath == 'common:url.contracts_detail' )
          && ( redirectAction == "view" || !redirectAction || redirectActionExecuted )
        }
    />
    <ListItem
      label={t('common:text.altHome_contract_download')}
      onClick={() => beginContractAction("download")}
      selected={location.pathname === t('common:url.contracts') && redirectAction == "download"}
    />
    <ListItem
      label={t('common:text.altHome_contract_power')}
      onClick={() => beginContractAction("powChange")}
      selected={location.pathname === t('common:url.contracts') && redirectAction == "powChange"}
    />
    <ListItem
      label={t('common:text.altHome_contract_iban')}
      onClick={() => beginContractAction("ibanChange")}
      selected={location.pathname === t('common:url.contracts') && redirectAction == "ibanChange"}
    />
    {Settings.features.notificacionAddressChange && (
      <ListItem
        label={t('common:text.altHome_contract_contact')}
        onClick={() => beginContractAction("notifAddrChange")}
        selected={location.pathname === t('common:url.contracts') && redirectAction == "notifAddrChange"}
      />
    )}
    {Settings.features.noCesionSips && (
      <ListItem
        label={t('common:text.altHome_contract_sips')}
        onClick={() => beginContractAction("noCesionSips")}
        selected={location.pathname === t('common:url.contracts') && redirectAction == "noCesionSips"}
      />
    )}
  </ListGroup>

  const InvoicesListGroup = <ListGroup 
    icon={<Receipt />}
    titleLabel={t('common:text.altHome_invoices_title')}
    startOpen={location.pathname === t('common:url.invoices')}
  >
    <ListItem
      label={t('common:text.altHome_invoices_view')}
      onClick={() => beginInvoiceAction("view")}
      selected={location.pathname === t('common:url.invoices') && (redirectAction == "view" || !redirectAction)}
    />
    <ListItem
      label={t('common:text.altHome_invoices_download')}
      onClick={() => beginInvoiceAction("download")}
      selected={location.pathname === t('common:url.invoices') && redirectAction == "download"}
    />
    {Settings?.invoices?.enablePayment && (
      <ListItem
        label={t('common:text.altHome_invoices_payonline')}
        onClick={() => beginInvoiceAction("payOnline")}
        selected={location.pathname === t('common:url.invoices') && redirectAction == "payOnline"}
      />
    )}
  </ListGroup>

  const ConsumptionListGroup = <ListGroup
    icon={<Equalizer />}
    titleLabel={t('common:text.altHome_consumption_title')}
    startOpen={location.pathname === t('common:url.contracts') && redirectAction == "consumption"}
  >
	  <ListItem
      label={t('common:text.altHome_consumption_view')}
      onClick={() => beginContractAction("consumption")}
      selected={location.pathname === t('common:url.contracts') && redirectAction == "consumption"}
    />
  </ListGroup>

  return <>
    <ListItem
      icon={<Apps />}
      label={t('common:text.home')}
      onClick={() => navigate('/')}
      selected={location.pathname === '/'}
    />

    {ContractListGroup}
    {InvoicesListGroup}
    {ConsumptionListGroup}

    {!isAuthenticated && <>
      {!Settings.features?.oidc && <ListItem
        icon={<Person />}
        label={t('common:text.login_title')}
        onClick={() => navigate(t('common:url.login'))}
        selected={location.pathname === t('common:url.login')}
      />}
      {Settings.features?.signup && <ListItem
        icon={<PersonAdd />}
        label={t('common:text.login_view_button_activate')}
        onClick={() => navigate(t('common:url.activate'))}
        selected={location.pathname === t('common:url.activate')}
      />}
      {Settings.features?.sidebarContract && <ListItem
        icon={<AddBox />}
        label={t('common:text.new_customer_title')}
        onClick={() => navigate(getContractationURL())}
        selected={location.pathname === getContractationURL()}
      />}
      {Settings.features?.unsignedContact && 
        Settings.features?.contact && <ListItem
        icon={<Message />}
        label={t('common:text.contact_title')}
        onClick={() => navigate(t('common:url.unsigned_contact'))}
        selected={location.pathname === t('common:url.unsigned_contact')}
      />}
    </>}
  </>
}

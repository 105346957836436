import { useContext } from "react"
import { SettingsProviderContext } from "../components/SettingsProvider"

export const useSettingsContext = () => {
  const context = useContext(SettingsProviderContext);

  if (!context) {
    throw new Error("useSettingsContext outside of SettingsProvider");
  }

  return context;
}
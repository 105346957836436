import { Alert, Box, CircularProgress, Stack, Typography } from "@mui/material";
import NewslettersSelector from "./NewslettersSelector";
import { useTranslation } from "react-i18next";
import NewsletterEmailInput from "./NewsletterEmailInput";
import useNewsletterPage from "./useNewsletterPage";
import NewsletterAlerts from "./NewsletterAlerts";
import NewsletterActions from "./NewsletterActions";

const NewsletterPage = () => {
  const { t } = useTranslation();
  const { state, subscriberId, emails, handleUpdateEmail, handleMailChangeConfirmed, newsletters, selectedNewsletters, setSelectedNewsletters, handleSubscribe  } = useNewsletterPage();

  return (
    <Box
      sx={{
        maxWidth: {
          sm: "100vw",
          md: "70vw",
          lg: "900px",
        },
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Stack gap={2}>

        <Typography variant="h4" component="h1">
          {t("text.newsletter_welcome")}
        </Typography>

        <Typography variant="body1" color="text.secondary">
          {subscriberId
            ? t("text.newsletter_instructions_mail_with_edition")
            : t("text.newsletter_instructions_mail")}
        </Typography>
        <NewsletterEmailInput
          value={emails ?? ""}
          onMailChangeConfirmed={handleMailChangeConfirmed}
          onEditNewEmailClick={(newEmail) => handleUpdateEmail(newEmail)}
          hideSubmitButton={!!emails}
          subscriberId={subscriberId}
        />
        
        {state.fetchNewsletters == "Fetching" &&
          <Box justifyContent="center" >
            <CircularProgress />
          </Box>
        }

        {!!newsletters.length && <>
          {(
            state.fetchNewsletters == "Success" || 
            state.submitSubcriptions == "Success") && 
            <>
              <Typography variant="body1" color="text.secondary">
                {t("text.newsletter_instructions_newsletter")}
              </Typography>
              <NewslettersSelector
                newsletters={newsletters}
                value={selectedNewsletters}
                onChange={(value) => setSelectedNewsletters(value)}
              />
            </>
          }

          {(
            state.fetchNewsletters == "Error" || 
            state.submitSubcriptions == "Error" ) && 
            <Alert severity="error">
              {t("text.newsletter_fetch_list_error")}
            </Alert>
          }

          <NewsletterActions
            onSubscribe={handleSubscribe}
            newsletters={newsletters}
            emails={emails}
            state={state}
            subscriberId={subscriberId}
            selectedNewsletters={selectedNewsletters}
          />
        </>}

        {!newsletters.length && state.fetchNewsletters == "Success" && <>
          <Alert severity="info">
            {t("text.newsletter_fetch_list_empty")}
          </Alert>
        </>}

        <NewsletterAlerts state={state}/>

        {!!emails && (
          <Typography variant="body2" color="text.secondary">
            {t("text.newsletter_mail_locked_explanation", { mail: emails })}
            <a href={t("common:url.newsletter")} target="_blank">
              {" "}
              {t("text.newsletter_mail_locked_link")}
            </a>
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default NewsletterPage;

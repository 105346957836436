import Settings from "@/settings";
import PaymentPaidSummaryButton from "../../../PaymentPaidSummaryButton";
import PaymentPendingStateHistoryButton from "../../../PaymentPendingStateHistoryButton";
import { Invoice } from "../../PaymentStatus";
import { ChipOwnProps } from "@mui/material";

const ChipedPaymentStatusInfoButton = (invoice: Invoice, paidColor: ChipOwnProps["color"]) => {
  let infoButton = null;

  switch (invoice?.paid) {
    case "paid":
      if ((invoice?.rectificative_type !== "A" && invoice?.rectificative_type !== "B") && Settings?.invoices?.enablePayment) {
        infoButton = <PaymentPaidSummaryButton invoice={invoice} paidColor={paidColor} />
      }
      break;
    case "manual_verification_required":
      infoButton = <PaymentPaidSummaryButton invoice={invoice} paidColor={paidColor} />;
      break;
    case "unpaid":
      if (invoice?.amount_pending && invoice?.pending_state_history && invoice?.pending_state_history.length > 1) {
        infoButton = <PaymentPendingStateHistoryButton invoice={invoice} paidColor={paidColor} />
      }
      break;
    default:
      infoButton = null;
      break;
  }

  return infoButton;
}

export default ChipedPaymentStatusInfoButton
import { createReducer } from '../utils/misc';

export const atcTipusReducer = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ATC_TIPUS':
      return action.payload;
    default:
      return state;
  }
};

const initialState = {
  selectedTipus: null,
};

export const atcSelectedTipusReducer = createReducer(initialState, {
  RECLAM_TYPE_PRELOAD: (state, payload) =>
  Object.assign({}, state, {
    selectedTipus: payload
  }),
});

export const atcSubtipusReducer = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ATC_SUBTIPUS':
      return action.payload;
    default:
      return state;
  }
};
